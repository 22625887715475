import { clone, isNil } from "ramda";
import axios from "axios";
import { toast } from "react-toastify";
import querystring from "querystring";
import { BASE_API_URL } from "../../config/configmap";
import { UNAUTHORIZED_USER } from "../utils/consts";
import {
  authFunction,
  getFromCookie,
  isOldPasswordValid,
  isTokenExpired,
  logout,
  setAuthToken,
  userWantToBeRemember,
} from "./auth";
import { createCookie, eraseCookie } from "../cookie";

axios.defaults.baseURL = BASE_API_URL;

export const login = async (username, password, remember_me) => {
  try {
    if (!isNil(username) && !isNil(password)) {
      const result = await authFunction(axios, "login", { username, password, remember_me });
      eraseCookie("uptechRememberMe");
      if (result && remember_me) {
        createCookie("uptechRememberMe", true, 30);
      }
      return result;
    }
  } catch (error) {
    throw Error(error.message);
  }
  return false;
};

export const register = async (username, password, remember) => {
  try {
    if (!isNil(username) && !isNil(password)) {
      const result = await authFunction(axios, "register", { username, password });
      eraseCookie("uptechRememberMe");
      if (result && remember) {
        createCookie("uptechRememberMe", true, 30);
      }
      return result;
    }
  } catch (error) {
    throw Error(error.message);
  }
  return false;
};

export const refreshTokenData = async () => {
  try {
    const refreshToken = getFromCookie("uptechRefreshToken");
    if (!isNil(refreshToken)) {
      const result = await authFunction(axios, "refreshToken", refreshToken);
      return result;
    }
  } catch (error) {
    throw Error(error.message);
  }
  return false;
};

export const verifyOldPassword = async (oldPassword) => {
  if (await preRequest()) {
    const result = await isOldPasswordValid(axios, oldPassword);
    return result;
  }
  throw Error(UNAUTHORIZED_USER);
};

export const getUserRoleData = async () => {
  try {
    if (await preRequest()) {
      const result = await axios.get("/roles");
      return result;
    }
  } catch (error) {
    throw Error(UNAUTHORIZED_USER);
  }
};

export const postRole = async (role, intl) => {
  if (await preRequest()) {
    const result = await axios.post("/roles", role);
    toast.success(intl.formatMessage({ id: result.data.message }), { className: "toast-success" });
    return result;
  }
  throw Error(UNAUTHORIZED_USER);
};

export const putRole = async (id, role, intl) => {
  if (await preRequest()) {
    const result = await axios.put(`/roles/${id}`, role);
    toast.success(intl.formatMessage({ id: result.data.message }), { className: "toast-success" });
    return result;
  }
  throw Error(UNAUTHORIZED_USER);
};

export const deleteRole = async (id, intl) => {
  if (await preRequest()) {
    const result = await axios.delete(`/roles/${id}`);
    toast.success(intl.formatMessage({ id: result.data.message }), { className: "toast-success" });
    return result;
  }
  throw Error(UNAUTHORIZED_USER);
};

export const getMenuData = async () => {
  if (await preRequest()) {
    const result = await axios.get("/menuOptions");
    return result;
  }
  throw Error(UNAUTHORIZED_USER);
};
export const getEquipmentTypes = async () => {
  if (await preRequest()) {
    const result = await axios.get("/equipmentTypes");
    return result;
  }
  throw Error(UNAUTHORIZED_USER);
};

export const getAssetsValues = async () => {
  if (await preRequest()) {
    const result = await axios.get("/data");
    return result;
  }
  throw Error(UNAUTHORIZED_USER);
};

export const getAssetsValuesByAssets = async (assets) => {
  try {
    if (await preRequest()) {
      const result = await axios.get(`/data?asset=${assets}`);
      return result;
    }
  } catch (error) {
    throw Error(UNAUTHORIZED_USER);
  }
};

export const getEquipments = async () => {
  try {
    if (await preRequest()) {
      const result = await axios.get("/equipments");
      return result;
    }
  } catch (error) {
    throw Error(UNAUTHORIZED_USER);
  }
};

export const getAllDocuments = async () => {
  try {
    if (await preRequest()) {
      const result = await axios.get("/documents");
      return result;
    }
  } catch (error) {
    throw Error(UNAUTHORIZED_USER);
  }
};

export const getDocuments = async (asset) => {
  try {
    if (await preRequest()) {
      const result = await axios.get("/documents", { params: { asset: [asset] } });
      return result;
    }
  } catch (error) {
    throw Error(UNAUTHORIZED_USER);
  }
};

export const getDocumentsFile = async (document) => {
  try {
    if (await preRequest()) {
      const { asset, filename, equipmentID } = document;
      const result = await axios.get("/documents/file", {
        params: { asset, filename, equipment_ID: equipmentID },
        responseType: "blob",
      });
      return result;
    }
  } catch (error) {
    console.error(error);
  }
};
export const postDocuments = async (document, newName, file, intl) => {
  try {
    if (await preRequest()) {
      const formData = new FormData();
      document.name = newName;
      formData.append("document", JSON.stringify(document));
      formData.append("file", file);
      const result = await axios.post("/documents", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      toast.success(intl.formatMessage({ id: result.data.message }), { className: "toast-success" });
      return result;
    }
  } catch (error) {
    toast.error(error.message, { className: "toast-error" });
  }
};
export const putDocument = async (document, newName, file, intl) => {
  try {
    if (await preRequest()) {
      const formData = new FormData();
      let newDocument = clone(document);
      newDocument.name = newName;
      formData.append("document", JSON.stringify(newDocument));
      if (!isNil(file)) {
        formData.append("file", file);
      }
      const result = await axios.put(`/documents/${document.id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      toast.success(intl.formatMessage({ id: result.data.message }), { className: "toast-success" });
      return result;
    }
  } catch (error) {
    toast.error(error.message, { className: "toast-error" });
  }
};
export const deleteDocument = async (document, intl) => {
  try {
    if (await preRequest()) {
      const result = await axios.delete(`/documents/${document.id}`);
      toast.success(intl.formatMessage({ id: result.data.message }), { className: "toast-success" });
      return result;
    }
  } catch (error) {
    toast.error(error.message, { className: "toast-error" });
  }
};

export const getPoints = async () => {
  try {
    if (await preRequest()) {
      const result = await axios.get("/points");
      return result;
    }
  } catch (error) {
    throw Error(UNAUTHORIZED_USER);
  }
};

export const getPointsByAssets = async (assets) => {
  try {
    if (await preRequest()) {
      const result = await axios.get(`/points?assets=${assets}`);
      return result;
    }
  } catch (error) {
    throw Error(UNAUTHORIZED_USER);
  }
};

export const getAssetsData = async () => {
  try {
    if (await preRequest()) {
      const result = await axios.get("/assets");
      return result;
    }
  } catch (error) {
    throw Error(UNAUTHORIZED_USER);
  }
};

export const postAsset = async (data, intl) => {
  if (await preRequest()) {
    const result = await axios.post("/assets", data);
    toast.success(intl.formatMessage({ id: result.data.message }), { className: "toast-success" });
    return result;
  }
  throw Error(UNAUTHORIZED_USER);
};

export const postEquipment = async (data, intl) => {
  try {
    if (await preRequest()) {
      const result = await axios.post("/equipments", data);
      toast.success(intl.formatMessage({ id: result.data.message }), { className: "toast-success" });
      return result;
    }
  } catch (error) {
    throw Error(UNAUTHORIZED_USER);
  }
};

export const getEquipmentsByAsset = async (assets) => {
  try {
    if (await preRequest()) {
      const result = await axios.get(`/equipments?assets=${assets}`);
      return result;
    }
  } catch (error) {
    throw Error(UNAUTHORIZED_USER);
  }
};

export const deleteData = async (assets, intl) => {
  try {
    if (await preRequest()) {
      const result = await axios.delete(`/data?assets=${assets}`);
      toast.success(intl.formatMessage({ id: result.data.message }), { className: "toast-success" });
      return result;
    }
  } catch (error) {
    throw Error(UNAUTHORIZED_USER);
  }
};

export const deleteEquipment = async (id, intl) => {
  if (await preRequest()) {
    const result = await axios.delete(`/equipments/${id}`);
    toast.success(intl.formatMessage({ id: result.data.message }), { className: "toast-success" });

    return result;
  }
  throw Error(UNAUTHORIZED_USER);
};

export const postPoint = async (data, intl) => {
  if (await preRequest()) {
    const result = await axios.post("/points", data);
    toast.success(intl.formatMessage({ id: result.data.message }), { className: "toast-success" });
    return result;
  }
  throw Error(UNAUTHORIZED_USER);
};

export const putAsset = async (data, intl) => {
  if (await preRequest()) {
    const result = await axios.put(`/assets/${data.id}`, data);
    toast.success(intl.formatMessage({ id: result.data.message }), { className: "toast-success" });
    return result;
  }
  throw Error(UNAUTHORIZED_USER);
};

export const putEquipment = async (data, intl) => {
  if (await preRequest()) {
    const result = await axios.put(`/equipments/${data.id}`, data);
    toast.success(intl.formatMessage({ id: result.data.message }), { className: "toast-success" });
    return result;
  }
  throw Error(UNAUTHORIZED_USER);
};

export const deleteAsset = async (id, intl) => {
  if (await preRequest()) {
    const result = await axios.delete(`/assets/${id}`);
    toast.success(intl.formatMessage({ id: result.data.message }), { className: "toast-success" });
    return result;
  }
  throw Error(UNAUTHORIZED_USER);
};

export const getAllAlertsData = async () => {
  try {
    if (await preRequest()) {
      const result = await axios.get("/alerts");
      return result;
    }
  } catch (error) {
    throw Error(UNAUTHORIZED_USER);
  }
};

export const getAlertData = async (id) => {
  try {
    if (await preRequest()) {
      const result = await axios.get(`/alerts/${id}`);
      return result;
    }
  } catch (error) {
    throw Error(UNAUTHORIZED_USER);
  }
};

export const putAlert = async (id, alert, intl) => {
  if (await preRequest()) {
    const result = await axios.put(`/alerts/${id}`, alert);
    toast.success(intl.formatMessage({ id: result.data.message }), { className: "toast-success" });
    return result;
  }
  throw Error(UNAUTHORIZED_USER);
};

export const deleteAlert = async (id) => {
  if (await preRequest()) {
    const result = await axios.delete(`/alerts/${id}`);
    return result;
  }
  throw Error(UNAUTHORIZED_USER);
};

export const getAllUsersData = async () => {
  try {
    if (await preRequest()) {
      const result = await axios.get("/users");
      return result;
    }
  } catch (error) {
    throw Error(UNAUTHORIZED_USER);
  }
};

export const getUserData = async (id) => {
  try {
    if (await preRequest()) {
      const result = await axios.get(`/users/${id}`);
      return result;
    }
  } catch (error) {
    throw Error(UNAUTHORIZED_USER);
  }
};

export const postUser = async (user, intl) => {
  if (await preRequest()) {
    const result = await axios.post("/users", user);
    toast.success(intl.formatMessage({ id: result.data.message }), { className: "toast-success" });
    return result;
  }
  throw Error(UNAUTHORIZED_USER);
};

export const putUser = async (id, user, intl) => {
  if (await preRequest()) {
    const result = await axios.put(`/users/${id}`, user);
    toast.success(intl.formatMessage({ id: result.data.message }), { className: "toast-success" });
    return result;
  }
  throw Error(UNAUTHORIZED_USER);
};

export const deleteUser = async (id, intl) => {
  if (await preRequest()) {
    const result = await axios.delete(`/users/${id}`);
    toast.success(intl.formatMessage({ id: result.data.message }), { className: "toast-success" });
    return result;
  }
  throw Error(UNAUTHORIZED_USER);
};

export const getConfigData = async () => {
  if (await preRequest()) {
    const result = await axios.get("/configurations");
    return result;
  }
  throw Error(UNAUTHORIZED_USER);
};

export const putConfigData = async (data, id, intl) => {
  if (await preRequest()) {
    const result = await axios.put(`/configurations/${id}`, data);
    toast.success(intl.formatMessage({ id: result.data.message }), { className: "toast-success" });
    return result;
  }
  throw Error(UNAUTHORIZED_USER);
};

export const getAnalysisSpatialData = async (asset = null, date = null, equipment_ID = null, channel = null) => {
  if (await preRequest()) {
    const queryParams = {};
    if (!isNil(asset)) {
      queryParams.asset = asset;
    }
    if (!isNil(date)) {
      queryParams.dateTime = date;
    }
    if (!isNil(equipment_ID)) {
      queryParams.equipment_ID = equipment_ID;
    }
    if (!isNil(channel)) {
      queryParams.channel = channel;
    }
    const queryString = querystring.stringify(queryParams);
    const result = await axios.get(`/data?${queryString}`);
    return result;
  }
  throw Error(UNAUTHORIZED_USER);
};
export const getAssetsDates = async (asset = null, equipment_ID = null, channel = null) => {
  try {
    if (await preRequest()) {
      const queryParams = {};
      if (!isNil(asset)) {
        queryParams.asset = asset.join(",");
      }
      queryParams.equipment_ID = equipment_ID;
      queryParams.channel = channel;
      const queryString = querystring.stringify(queryParams);
      const result = await axios.get(`/dataDates?${queryString}`);
      return result;
    }
  } catch (error) {
    throw Error(UNAUTHORIZED_USER);
  }
};

export const getAssetsDatetimes = async (asset = null, equipment_ID = null, channel = null, date = null) => {
  try {
    if (await preRequest()) {
      const queryParams = {};
      if (!isNil(asset)) {
        queryParams.asset = asset.join(",");
      }
      queryParams.equipment_ID = equipment_ID;
      queryParams.channel = channel;
      queryParams.date = date;
      const queryString = querystring.stringify(queryParams);
      const result = await axios.get(`/dataTimes?${queryString}`);
      return result;
    }
  } catch (error) {
    throw Error(UNAUTHORIZED_USER);
  }
};

export const getAssetPointsSerialData = async (
  asset,
  startIndex,
  endIndex,
  endDate,
  fields,
  equipment,
  channel,
  user_period,
  maximum,
  startDate,
  afterAlert,
) => {
  if (await preRequest()) {
    const queryParams = { asset, equipment, channel, startIndex, endDate, fields };
    if (!isNil(startDate)) {
      queryParams.startDate = startDate;
    }

    if (!isNil(endIndex)) {
      queryParams.endIndex = endIndex;
    }
    if (user_period !== Number.POSITIVE_INFINITY && afterAlert) {
      queryParams.user_period = user_period;
    }
    if (maximum !== Number.POSITIVE_INFINITY) {
      queryParams.maximum = maximum;
    }
    const queryString = querystring.stringify(queryParams);
    const result = await axios.get(`/temporalData?${queryString}`);
    return result;
  }
  throw Error(UNAUTHORIZED_USER);
};

export const getAssetPointsAdditionalData = async (
  asset,
  endDate,
  fields,
  equipment,
  user_period,
  maximum,
  channel,
  startDate,
  afterAlert,
  sensor_ID,
  startIndex,
  endIndex,
) => {
  if (await preRequest()) {
    const queryParams = { asset, endDate, fields, sensor_ID };
    if (!isNil(startDate)) {
      queryParams.startDate = startDate;
    }

    if (user_period !== Number.POSITIVE_INFINITY && afterAlert) {
      queryParams.user_period = user_period;
    }
    if (maximum !== Number.POSITIVE_INFINITY) {
      queryParams.maximum = maximum;
    }
    const queryString = querystring.stringify(queryParams);
    const result = await axios.get(`/assets/additionalData/serial-time?${queryString}`);
    return result;
  }
  throw Error(UNAUTHORIZED_USER);
};

export const getBooleanAnalysisSpatialData = async (asset = null, date = null) => {
  if (await preRequest()) {
    const queryParams = {};
    if (!isNil(asset)) {
      queryParams.asset = asset;
    }
    if (!isNil(date)) {
      queryParams.dateTime = date;
    }
    const queryString = querystring.stringify(queryParams);
    const result = await axios.get(`/accumulativesData?${queryString}`);
    return result;
  }
  throw Error(UNAUTHORIZED_USER);
};

const preRequest = async () => {
  try {
    if (isTokenExpired()) {
      if (userWantToBeRemember()) {
        const refreshSuccess = await refreshTokenData();
        if (!refreshSuccess) {
          return false;
        }
      } else {
        return false;
      }
    }
    setAuthToken(axios, getFromCookie("uptechAccessToken"));
    return true;
  } catch (error) {
    logout();
    throw Error(error.message);
  }
};

export const putForgotPassword = async (email, intl, setLoading) => {
  try {
    if (await preRequest()) {
      const result = await axios.put(`/auth/forgotPassword?email=${email}`);

      toast.success(intl.formatMessage({ id: result.data.message }), { className: "toast-success" });

      setLoading(false);
      return result;
    }
  } catch (error) {
    toast.error(intl.formatMessage({ id: error.response.data.detail }), { className: "toast-error" });
  }
};
