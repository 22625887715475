import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import { isEmpty, isNil } from "ramda";

import AddIcon from "@mui/icons-material/Add";
import { Box, Avatar, Tooltip, Badge } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";

import {
  getAlertThemeData,
  getGridLocalTextTheme,
  getGridTheme,
  getImageSrc,
  menuAccessVerification,
} from "../../utils/utils";
import { getColumns, handleDeleteUser, loadUsers } from "./utils/utils";
import palette from "../../../config/colorPalette";
import EditToolbar from "../../components/EditToolbar";
import UserModal from "./components/UserModal";
import TextModal from "../../components/TextModal";
import avatar from "../../../images/avatar.png";
import { loadRoles } from "../Roles/utils/utils";

const Users = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl();

  const userData = useSelector((state) => state.userData.data);
  const menuData = useSelector((state) => state.menuData.data);
  const localeData = useSelector((state) => state.configData.locale);

  const gridLocalTextTheme = useMemo(() => getGridLocalTextTheme(localeData), [localeData]);
  const gridTheme = useMemo(() => getGridTheme(), []);

  const [users, setUsers] = useState();
  const [roles, setRoles] = useState();
  const [loading, setLoading] = useState(true);
  const [openUserModal, setOpenUserModal] = useState(false);
  const [openTextModal, setOpenTextModal] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [modalType, setModalType] = useState("create");

  const columns = useMemo(() => {
    let result = [];
    if (!isNil(users) && !isNil(userData)) {
      result = getColumns(intl);
      result[1] = {
        ...result[1],
        renderCell: (params) => {
          let avatarSrc = getImageSrc(params?.value);
          return <Avatar alt="avatar" src={avatarSrc ? avatarSrc : avatar} sx={{ width: 30, height: 30 }} />;
        },
      };
      result[5] = {
        ...result[5],
        renderCell: (params) => {
          const alertData = getAlertThemeData(1, "medium", intl);
          return (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
              }}
            >
              {params.row.measurementAlerts === 456 ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <ClearIcon />
                </Box>
              ) : (
                <Tooltip title={alertData.title} arrow>
                  <Badge
                    sx={{
                      backgroundColor: alertData.backgroundColor,
                      justifyContent: "center",
                      borderRadius: "16px",
                      padding: "5px",
                    }}
                  >
                    {alertData.icon}
                  </Badge>
                </Tooltip>
              )}
            </Box>
          );
        },
      };

      result[6] = {
        ...result[6],
        renderCell: (params) => {
          return (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
              }}
            >
              {params.row.equipmentAlerts ? <CheckIcon /> : <ClearIcon />}
            </Box>
          );
        },
      };
      result[result.length - 1].getActions = ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<VisibilityIcon />}
            label="View"
            className="textPrimary"
            onClick={() => {
              setCurrentUser(users.find((item) => item.id === id));
              setModalType("view");
              setOpenUserModal(true);
            }}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={() => {
              setCurrentUser(users.find((item) => item.id === id));
              setModalType("edit");
              setOpenUserModal(true);
            }}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            className="textPrimary"
            disabled={id === userData.id}
            onClick={() => {
              setCurrentUser(users.find((item) => item.id === id));
              setOpenTextModal(true);
            }}
            color="inherit"
          />,
        ];
      };
    }
    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl, users, userData]);

  useEffect(() => {
    if (menuAccessVerification("/users", menuData, navigate)) {
      loadUsers(setUsers, setLoading, dispatch, navigate);
      loadRoles(setRoles, setLoading, intl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuData]);

  const handleNewUserBtnClick = async () => {
    setModalType("create");
    setCurrentUser(null);
    setOpenUserModal(true);
  };

  if (isNil(users) || loading) {
    return (
      <div
        style={{ height: "92vh", display: "flex", justifyContent: "center", alignItems: "center" }}
      >{`${intl.formatMessage({ id: "loading" })}...`}</div>
    );
  }

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div style={{ fontSize: "x-large", padding: "1rem", width: "100%" }}>{intl.formatMessage({ id: "users" })}</div>
      <Box
        sx={{
          minHeight: 200,
          height: isNil(users) || isEmpty(users) ? 400 : "100%",
          width: "99%",
          padding: "1rem",
          "& .actions": {
            color: palette.secondary,
          },
          "& .textPrimary": {
            color: palette.primary,
          },
        }}
      >
        <DataGrid
          rows={users.map((user) => ({
            ...user,
            alertsType: intl.formatMessage({
              id:
                user.alertsType === 0
                  ? "none"
                  : user.alertsType === 1
                  ? "low"
                  : user.alertsType === 2
                  ? "intermediate"
                  : "high",
            }),
            assets: user.demarcations.join(", "),
          }))}
          columns={columns}
          columnVisibilityModel={{ id: false }}
          loading={loading}
          localeText={gridLocalTextTheme.components.MuiDataGrid.defaultProps.localeText}
          pagination
          disableRowSelectionOnClick
          slots={{
            toolbar: EditToolbar,
          }}
          slotProps={{
            toolbar: {
              btnText: intl.formatMessage({ id: "add user" }),
              btnIcon: <AddIcon />,
              handleClick: handleNewUserBtnClick,
            },
          }}
          sx={gridTheme}
        />
      </Box>
      {openUserModal && (
        <UserModal
          open={openUserModal}
          user={currentUser}
          roles={roles}
          setOpen={setOpenUserModal}
          type={modalType}
          acceptFunction={() => loadUsers(setUsers, setLoading, dispatch, navigate)}
        />
      )}
      <TextModal
        open={openTextModal}
        setOpen={setOpenTextModal}
        title={`${intl.formatMessage({ id: "delete user" })}: "${currentUser?.username}"`}
        text={intl.formatMessage({ id: "delete.user.text" })}
        acceptFunction={() =>
          handleDeleteUser({ id: currentUser?.id, setUsers, setLoading, setOpenTextModal, dispatch, navigate, intl })
        }
      />
    </div>
  );
};

export default Users;
