import { clone, isEmpty, isNil } from "ramda";
import { putAsset, putConfigData, putEquipment } from "../../../api";
import { getAssetsDataAction } from "../../../redux/assetsSlice";
import { toast } from "react-toastify";
import { getConfigDataSuccess } from "../../../redux/configSlice";

export const onRemoveHandler = ({
  measureIndex,
  channelIndex,
  zoneIndex,
  index2,
  tuples,
  setTuples,
  tuplesActive,
  setTuplesActive,
}) => {
  let localTuples = clone(tuples);
  localTuples[measureIndex][channelIndex][zoneIndex][index2] = 0;
  setTuples(localTuples);
  let localActive = clone(tuplesActive);
  localActive[measureIndex][channelIndex][zoneIndex][index2] = 0;
  setTuplesActive(localActive);
};

export const onValueChangeHandler = ({
  value,
  measureIndex,
  channelIndex,
  zoneIndex,
  index2,
  tuples,
  setTuples,
  tuplesActive,
  setTuplesActive,
}) => {
  if (!isNil(value)) {
    const localTuples = clone(tuples);

    const modifiedTuple = [...localTuples[measureIndex][channelIndex][zoneIndex]];
    modifiedTuple[index2] = value;
    localTuples[measureIndex][channelIndex][zoneIndex] = modifiedTuple;
    setTuples(localTuples);
  }
};

export const onBlurHandler = ({
  idErrorPrefix,
  measureIndex,
  channelIndex,
  zoneIndex,
  tuples,
  errors,
  setErrors,
  setTuples,
  tuplesActive,
  setTuplesActive,
  intl,
}) => {
  const newErrors = { ...errors };
  const localTuples = [...tuples];

  for (let j = localTuples[measureIndex][channelIndex][zoneIndex].length - 1; j >= 0; j--) {
    let isGreater = true;

    for (let k = 0; k < j; k++) {
      const active1 = tuplesActive[measureIndex][channelIndex][zoneIndex][j];
      const active2 = tuplesActive[measureIndex][channelIndex][zoneIndex][k];
      if (active1 === 1 && active2 === 1) {
        if (
          localTuples[measureIndex][channelIndex][zoneIndex][j] <= localTuples[measureIndex][channelIndex][zoneIndex][k]
        ) {
          isGreater = false;
          break;
        }
      }
    }

    if (!isGreater) {
      newErrors[`${idErrorPrefix}-${j}`] = `greater.previous`;
    } else {
      delete newErrors[`${idErrorPrefix}-${j}`];
    }
  }

  setTuples(localTuples);
  setErrors(newErrors);
};

export const updateData = async ({
  selectedAsset,
  selectedEquipment,
  alertTimeRange,
  asignedChannelNames,
  asignedChannelLength,
  asignedChannelSpatialResolution,
  asignedChannelSamplingInterval,
  asignedChannelMeasurementTime,
  channelsSensorsIDs,
  tuples,
  tuplesActive,
  measuresAlerts,
  thresholdsRanges,
  equipments,
  setLoading,
  dispatch,
  assets,
  role,
  currentUser,
  intl,
}) => {
  setLoading(true);
  const data = {
    id: selectedAsset.id,
    name: selectedAsset.name,
    zoom: selectedAsset.zoom,
    centroid: selectedAsset.centroid,
    image: selectedAsset.image,
    dateTime: selectedAsset.dateTime,
    demarcation: selectedAsset.demarcation,
    timezone: selectedAsset.timezone,
    sensors: selectedAsset.sensors,
  };
  try {
    let result = await putAsset(data, intl);
    if (result.status === 200) {
      if (role === "admin") {
        const finalEquipments = [];
        const updatedEquipment = { ...selectedEquipment };
        updatedEquipment.channelsNames = asignedChannelNames;
        updatedEquipment.channelsLength = asignedChannelLength;
        updatedEquipment.channelsSpatialResolution = asignedChannelSpatialResolution;
        updatedEquipment.channelsSamplingInterval = asignedChannelSamplingInterval;
        updatedEquipment.channelsMeasurementTime = asignedChannelMeasurementTime;
        updatedEquipment.pThresholds = tuples;
        updatedEquipment.pThresholdsActive = tuplesActive;
        updatedEquipment.pMeasureAlerts = measuresAlerts;
        updatedEquipment.pThresholdsRanges = thresholdsRanges;
        updatedEquipment.channelsSensorsIDs = channelsSensorsIDs;
        result = await putEquipment(updatedEquipment, intl);

        if (result.status !== 200) {
          toast.error(`Error: An equipment was not updated`, { className: "toast-error" });
        } else {
          finalEquipments.push(updatedEquipment);
        }
      }
      const newAssets = [...assets.data];
      const assetIndex = newAssets.findIndex((item) => item.name === selectedAsset.name);
      newAssets[assetIndex] = { ...data, equipments: equipments };
      dispatch(getAssetsDataAction(currentUser.id));
    }
  } catch (error) {
    toast.error(error.message, { className: "toast-error" });
  }
  setLoading(false);
};

export const updateDataReloadTime = async (currentConfig, dataReloadTime, dispatch, setLoading, intl) => {
  setLoading(true);
  let data = clone(currentConfig.data);
  data.loadTime = parseInt(dataReloadTime);
  try {
    const result = await putConfigData(data, currentConfig.data.id, intl);
    if (result.status === 200) {
      const newConfig = { ...currentConfig };
      newConfig.data = data;
      dispatch(getConfigDataSuccess(newConfig.data));
    }
  } catch (error) {
    toast.error(error.message, { className: "toast-error" });
  }
  setLoading(false);
};

export const handleChannels = ({
  e,
  tuples,
  setTuples,
  thresholdsRanges,
  setThresholdsRanges,
  tuplesActive,
  setTuplesActive,
  channelToggle,
  setChannelToggle,
  zoneToggle,
  setZoneToggle,
  selectedEquipment,
  errors,
  setErrors,
}) => {
  const newErrors = { ...errors };
  if (isEmpty(e.target.value)) {
    newErrors[e.target.id] = "not.empty";
  } else {
    delete newErrors[e.target.id];
  }
  setErrors(newErrors);

  let newTuples = clone(tuples);
  let newThresholdRanges = clone(thresholdsRanges);
  let newTuplesActive = clone(tuplesActive);
  let newChannelToggle = clone(channelToggle);
  let newZoneToggle = clone(zoneToggle);

  if (parseInt(e.target.value) > selectedEquipment.channels.length) {
    for (let newChannels = 0; newChannels < parseInt(e.target.value); newChannels++) {
      for (let measureIndex = 0; measureIndex < selectedEquipment.pMeasureNames.length; measureIndex++) {
        if (
          isNil(newTuples[measureIndex][newChannels]) &&
          isNil(newTuplesActive[measureIndex][newChannels]) &&
          isNil(newTuples[measureIndex][newChannels])
        ) {
          newTuples[measureIndex].push([]);
          newTuplesActive[measureIndex].push([]);
          newThresholdRanges[measureIndex].push([]);
        }
        if (isNil(newTuples[measureIndex][newChannels][0])) {
          newTuples[measureIndex][newChannels].push([0]);
          newTuplesActive[measureIndex][newChannels].push([0, 0, 0]);
        }
        if (isNil(newThresholdRanges[measureIndex][newChannels][0])) {
          newThresholdRanges[measureIndex][newChannels].push("0-1");
        }
        if (isNil(newChannelToggle[measureIndex][newChannels])) {
          newChannelToggle[measureIndex].push(false);
        }
        if (isNil(newZoneToggle[measureIndex][newChannels])) {
          newZoneToggle[measureIndex].push([]);
          newZoneToggle[measureIndex][newChannels].push(false);
        }
      }
    }
  } else {
    for (let measureIndex = 0; measureIndex < selectedEquipment.pMeasureNames.length; measureIndex++) {
      newTuples[measureIndex].splice(parseInt(e.target.value));
      newTuplesActive[measureIndex].splice(parseInt(e.target.value));
      newThresholdRanges[measureIndex].splice(parseInt(e.target.value));
      newChannelToggle[measureIndex].splice(parseInt(e.target.value));
      newZoneToggle[measureIndex].splice(parseInt(e.target.value));
    }
  }
  setTuples(newTuples);
  setTuplesActive(newTuplesActive);
  setThresholdsRanges(newThresholdRanges);
  setChannelToggle(newChannelToggle);
  setZoneToggle(newZoneToggle);
};
export const handleZones = ({
  e,
  tuples,
  setTuples,
  thresholdsRanges,
  setThresholdsRanges,
  zoneToggle,
  setZoneToggle,
  errors,
  setErrors,
  measureIndex,
  channelIndex,
  tuplesActive,
  setTuplesActive,
}) => {
  const newErrors = { ...errors };
  if (isEmpty(e.target.value)) {
    newErrors[e.target.id] = "not.empty";
  } else {
    delete newErrors[e.target.id];
  }
  setErrors(newErrors);
  let newTuples = clone(tuples);
  let newTuplesActive = clone(tuplesActive);
  let newThresholdRanges = clone(thresholdsRanges);
  let newZoneToggle = clone(zoneToggle);
  if (parseInt(e.target.value) > newThresholdRanges[measureIndex][channelIndex].length) {
    for (let newValue = 0; newValue < parseInt(e.target.value); newValue++) {
      if (isNil(newTuples[measureIndex][channelIndex][newValue])) {
        const test = newTuples[measureIndex][channelIndex];
        test.push([]);
        newTuples[measureIndex][channelIndex][newValue] = [0, 0, 0];
        newTuplesActive[measureIndex][channelIndex].push([]);
        newTuplesActive[measureIndex][channelIndex][newValue] = [0, 0, 0];
      }
      if (isNil(newThresholdRanges[measureIndex][channelIndex][newValue])) {
        newThresholdRanges[measureIndex][channelIndex].push([]);
        const parts = newThresholdRanges[measureIndex][channelIndex][newValue - 1].split("-");
        const num2 = parseInt(parts[1], 10);
        newThresholdRanges[measureIndex][channelIndex][newValue] = `${num2 + 1}-${num2 + 2}`;
      }
      if (isNil(newZoneToggle[measureIndex][channelIndex][newValue])) {
        newZoneToggle[measureIndex][channelIndex].push(false);
      }
    }
  } else {
    newTuples[measureIndex][channelIndex].splice(parseInt(e.target.value));
    newTuplesActive[measureIndex][channelIndex].splice(parseInt(e.target.value));
    newThresholdRanges[measureIndex][channelIndex].splice(parseInt(e.target.value));
    newZoneToggle[measureIndex][channelIndex].splice(parseInt(e.target.value));
  }

  setThresholdsRanges(newThresholdRanges);
  setTuples(newTuples);
  setZoneToggle(newZoneToggle);
  setTuplesActive(newTuplesActive);
};
export const minHandler = ({
  e,
  num1,
  num2,
  thresholdsRanges,
  setThresholdsRanges,
  measureIndex,
  channelIndex,
  zoneIndex,
  errors,
  setErrors,
}) => {
  const prevId = `equipment_${measureIndex}_${channelIndex}_${zoneIndex - 1}_1`;
  const id = `equipment_${measureIndex}_${channelIndex}_${zoneIndex}_0`;
  const nextId = `equipment_${measureIndex}_${channelIndex}_${zoneIndex}_1`;
  const newErrors = { ...errors };
  if (isEmpty(e.target.value)) {
    newErrors[id] = "not.empty";
  }
  let newRange = clone(thresholdsRanges);
  const value = parseInt(e.target.value);
  const previousZone = newRange[measureIndex][channelIndex][zoneIndex - 1];
  if (isNil(previousZone) && value < num2) {
    delete newErrors[id];
    delete newErrors[nextId];
  } else if (isNil(previousZone) && value >= num2) {
    newErrors[id] = "min.lower.max";
  }
  if (!isNil(previousZone)) {
    const parts = previousZone.split("-");
    const prev1 = parseInt(parts[1], 10);
    if (prev1 < value && value < num2) {
      delete newErrors[prevId];
      delete newErrors[id];
      delete newErrors[nextId];
    } else if (prev1 >= value || value <= num2) {
      newErrors[id] = "prevMax.thisMax";
    }
  }
  newRange[measureIndex][channelIndex][zoneIndex] = `${value}-${num2}`;
  setThresholdsRanges(newRange);
  setErrors(newErrors);
};

export const maxHandler = ({
  e,
  num1,
  num2,
  thresholdsRanges,
  setThresholdsRanges,
  measureIndex,
  channelIndex,
  zoneIndex,
  errors,
  setErrors,
}) => {
  const prevId = `equipment_${measureIndex}_${channelIndex}_${zoneIndex}_0`;
  const id = `equipment_${measureIndex}_${channelIndex}_${zoneIndex}_1`;
  const nextId = `equipment_${measureIndex}_${channelIndex}_${zoneIndex + 1}_0`;
  const newErrors = { ...errors };
  if (isEmpty(e.target.value)) {
    newErrors[id] = "not.empty";
  }
  let newRange = clone(thresholdsRanges);
  const value = parseInt(e.target.value);
  const nextZone = newRange[measureIndex][channelIndex][zoneIndex + 1];
  if (isNil(nextZone) && value > num1) {
    delete newErrors[prevId];
    delete newErrors[id];
  } else if (isNil(nextZone) && value <= num1) {
    newErrors[id] = "max.higher.min";
  }
  if (!isNil(nextZone)) {
    const parts = nextZone.split("-");
    const next0 = parseInt(parts[0], 10);
    if (next0 > value && value > num1) {
      delete newErrors[prevId];
      delete newErrors[id];
      delete newErrors[nextId];
    } else if (next0 <= value || value <= num1) {
      newErrors[id] = "thisMin.nextMin";
    }
  }
  const thisZone = newRange[measureIndex][channelIndex][zoneIndex];
  const parts = thisZone.split("-");
  const this0 = parseInt(parts[0], 10);
  const prevZone = newRange[measureIndex][channelIndex][zoneIndex - 1];
  if (!isNil(prevZone)) {
    const parts2 = prevZone.split("-");
    const prev1 = parseInt(parts2[1], 10);
    minHandler({
      e: { target: { value: this0 } },
      num1: prev1,
      num2: value,
      thresholdsRanges,
      measureIndex,
      channelIndex,
      zoneIndex,
      setThresholdsRanges,
      newErrors,
      setErrors,
    });
  } else {
    setErrors(newErrors);
  }

  newRange[measureIndex][channelIndex][zoneIndex] = `${num1}-${value}`;
  setThresholdsRanges(newRange);
};
export const handleActiveThresholds = ({
  event,
  tuples,
  tuplesActive,
  index2,
  measureIndex,
  channelIndex,
  zoneIndex,
  setTuples,
  setTuplesActive,
}) => {
  let newTuplesActive = clone(tuplesActive);
  let newTuples = clone(tuples);
  if (event.target.checked) {
    const whereToAdd = index2;

    const nextTo = newTuplesActive[measureIndex][channelIndex][zoneIndex].lastIndexOf(1);
    let value = null;
    if (whereToAdd > nextTo) {
      const max = Math.max(...newTuples[measureIndex][channelIndex][zoneIndex]);
      value = max + 1;
    } else {
      const filtro = newTuples[measureIndex][channelIndex][zoneIndex][nextTo];
      value = filtro - (nextTo - whereToAdd);
    }
    newTuples[measureIndex][channelIndex][zoneIndex][whereToAdd] = value;

    newTuplesActive[measureIndex][channelIndex][zoneIndex][index2] = 1;
  } else {
    newTuplesActive[measureIndex][channelIndex][zoneIndex][index2] = 0;
  }
  setTuples(newTuples);
  setTuplesActive(newTuplesActive);
};
