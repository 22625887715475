import { isNil } from "ramda";

import { verifyRequestError } from "../../../utils/utils";
import { deleteAlert, getAllAlertsData, putAlert } from "../../../api";

export const loadAlerts = async ({ assetName, setAlerts, setLoading, dispatch, navigate, intl }) => {
  setLoading(true);
  try {
    const response = await getAllAlertsData();
    if (!isNil(response?.status) && response?.status === 200 && !isNil(response.data)) {
      const alerts = response.data.data;
      alerts.sort((a, b) => {
        if (!isNil(assetName) && a.asset === assetName && b.asset !== assetName) {
          return -1;
        } else if (!isNil(assetName) && a.asset !== assetName && b.asset === assetName) {
          return 1;
        } else {
          const dA = new Date(a.dateStart);
          const dB = new Date(b.dateStart);
          return dB - dA;
        }
      });
      await setAlerts(alerts);
    }
  } catch (error) {
    verifyRequestError({ error, dispatch, navigate, intl });
  }
  setLoading(false);
};

export const getColumnsMeasurement = (intl) => {
  const columns = [
    { field: "id", headerName: "Id", hide: true, disableExport: true },
    { field: "asset", headerName: intl.formatMessage({ id: "structure" }), flex: 1 },
    { field: "equipmentID", headerName: intl.formatMessage({ id: "equipment" }), flex: 1.4 },
    { field: "channel", headerName: intl.formatMessage({ id: "channel" }), flex: 1.4 },
    { field: "measure", headerName: intl.formatMessage({ id: "measurand" }), flex: 1.2 },
    {
      field: "customValue",
      headerName: intl.formatMessage({ id: "registered value" }),
      flex: 1.2,
      disableExport: true,
    },
    { field: "refValue", headerName: intl.formatMessage({ id: "referenced value" }), flex: 1.2 },
    { field: "dateStart", headerName: intl.formatMessage({ id: "start.date" }), flex: 1.5 },
    { field: "dateEnd", headerName: intl.formatMessage({ id: "end.date" }), flex: 1.5 },
    { field: "latitude", headerName: intl.formatMessage({ id: "latitude" }), flex: 1 },
    { field: "longitude", headerName: intl.formatMessage({ id: "longitude" }), flex: 1 },
    { field: "startPoint", headerName: intl.formatMessage({ id: "start point" }), flex: 1 },
    { field: "endPoint", headerName: intl.formatMessage({ id: "end point" }), flex: 1 },
    {
      field: "actions",
      type: "actions",
      headerName: intl.formatMessage({ id: "actions" }),
      width: 100, // Set a fixed width for action buttons
      cellClassName: "actions",
      getActions: null,
      disableExport: true,
    },
  ];
  return columns;
};
export const getColumnsEquipment = (intl) => {
  const columns = [
    { field: "id", headerName: "Id", hide: true, disableExport: true },
    { field: "asset", headerName: intl.formatMessage({ id: "structure" }), flex: 1.5 },
    {
      field: "equipment",
      headerName: intl.formatMessage({ id: "equipment" }),
      flex: 1.2,
      disableExport: true,
    },
    {
      field: "customValue",
      headerName: intl.formatMessage({ id: "registered value" }),
      flex: 1,
      disableExport: true,
    },
    { field: "dateStart", headerName: intl.formatMessage({ id: "date" }), flex: 1.5 },
    { field: "dateEnd", headerName: intl.formatMessage({ id: "end.date" }), flex: 1.5 },
    { field: "description", headerName: intl.formatMessage({ id: "description" }), flex: 2 },
    {
      field: "actions",
      type: "actions",
      headerName: intl.formatMessage({ id: "actions" }),
      width: 100,
      cellClassName: "actions",
      getActions: null,
      disableExport: true,
    },
  ];
  return columns;
};

export const handleDeleteAlert = async ({
  id,
  alerts,
  setAlerts,
  setDisplayAlerts,
  setLoading,
  setOpenTextModal,
  dispatch,
  navigate,
  intl,
}) => {
  if (isNil(id)) {
    // delete all
    if (!isNil(alerts)) {
      try {
        await alerts.forEach(async (item) => {
          await deleteAlert(item.id);
        });
        loadAlerts({ setAlerts, setLoading, dispatch, navigate, intl });
        setOpenTextModal(false);
      } catch (error) {
        throw Error("The alert was not deleted");
      }
    }
    return;
  }
  try {
    const response = await deleteAlert(id);
    if (!isNil(response)) {
      loadAlerts({ setAlerts, setLoading, dispatch, navigate, intl });
      setOpenTextModal(false);
    }
  } catch (error) {
    verifyRequestError({ error, dispatch, navigate, intl });
  }
  const updatedDisplayAlerts = alerts.filter((ua) => ua.id !== id);
  setDisplayAlerts(updatedDisplayAlerts);
};

export const putAlarms = async ({ id, alerts, setAlerts, displayAlerts, setDisplayAlerts, intl }) => {
  let toSendAlert = displayAlerts.find((item) => item.id === id);
  if (toSendAlert.isArchived) {
    toSendAlert.isArchived = false;
  } else {
    toSendAlert.isArchived = true;
  }
  await putAlert(id, toSendAlert, intl);

  const updatedDisplayAlerts = displayAlerts.map((alert) =>
    alert.id === id ? { ...alert, isArchived: toSendAlert.isArchived } : alert,
  );
  setDisplayAlerts(updatedDisplayAlerts);

  if (setAlerts) {
    const updatedAlerts = alerts.map((alert) =>
      alert.id === id ? { ...alert, isArchived: toSendAlert.isArchived } : alert,
    );
    setAlerts(updatedAlerts);
  }
};
