import React, { useEffect, useMemo, useState } from "react";
import { isEmpty, isNil } from "ramda";
import { format } from "date-fns";

import ToolbarDeleteIcon from "@mui/icons-material/Delete";
import { Box, Chip, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import ArchiveIcon from "@mui/icons-material/Archive";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";

import { getAlertThemeData, getGridLocalTextTheme, getGridTheme, handlePointClick } from "../../../../utils/utils";
import palette from "../../../../../config/colorPalette";
import TextModal from "../../../../components/TextModal";
import EditToolbar from "../../../../components/EditToolbar";
import { getColumnsEquipment, getColumnsMeasurement, handleDeleteAlert, putAlarms } from "../../utils/utils";

const AlarmTable = ({
  alerts,
  setAlerts,
  assetName,
  userData,
  assetsData,
  assetsPoints,
  pointsData,
  localeData,
  menuData,
  isEquipment,
  loading,
  setLoading,
  navigate,
  dispatch,
  intl,
}) => {
  const gridLocalTextTheme = useMemo(() => getGridLocalTextTheme(localeData), [localeData]);
  const gridTheme = useMemo(() => getGridTheme(), []);
  const [pageSize, setPageSize] = React.useState(10);

  const [openTextModal, setOpenTextModal] = useState(false);
  const [openDeleteAllModal, setOpenDeleteAllModal] = useState(false);
  const [currentAlert, setCurrentAlert] = useState(null);
  const [archived, setArchived] = useState(false);
  const [displayAlerts, setDisplayAlerts] = useState([]);

  useEffect(() => {
    if (!isNil(alerts) && !isEmpty(alerts)) {
      const auxAlerts = alerts.filter((alert) => alert.isArchived === archived);
      setDisplayAlerts(auxAlerts);
    }
  }, [archived, alerts]);

  const columns = useMemo(() => {
    let result = [];
    if (!isNil(alerts) && !isNil(userData) && !isNil(assetsData?.data) && !isNil(assetsPoints)) {
      if (isEquipment) {
        result = getColumnsEquipment(intl);
        result[2] = {
          ...result[2],
          renderCell: (params) => {
            const assetData = assetsData?.data.find((asset) => asset.name === params.row.asset);
            const equipment = assetData.equipments.find((equp) => equp.equipmentID === params.row.equipmentID);
            return <span>{equipment.name}</span>;
          },
        };
        result[3] = {
          ...result[3],
          renderCell: (params) => {
            const alertData = getAlertThemeData(params.row.type, "medium", intl);
            return (
              <Tooltip title={alertData.title} arrow>
                <Chip
                  icon={alertData.icon}
                  label={Math.round(params.value * 100) / 100}
                  sx={{ backgroundColor: alertData.backgroundColor }}
                />
              </Tooltip>
            );
          },
        };
        result[4] = {
          ...result[4],
          renderCell: (params) => {
            const alertData = params.row;
            const fechaOriginal = alertData.dateStart;
            const fechaFormateada = fechaOriginal.replace("T", " ").replace(/(\+\d{2}:\d{2})/, "");
            return <span>{fechaFormateada}</span>;
          },
        };

        result[5] = {
          ...result[5],
          renderCell: (params) => {
            const alertData = params.row;
            const fechaOriginal = alertData.dateEnd;
            const fechaFormateada = fechaOriginal.replace("T", " ").replace(/(\+\d{2}:\d{2})/, "");
            return <span>{fechaFormateada}</span>;
          },
        };
        result[result.length - 1].getActions = ({ id }) => {
          const auxAlert = displayAlerts.find((item) => item.id === id);
          return [
            <Tooltip title={intl.formatMessage({ id: auxAlert.isArchived ? "unarchive" : "archive" })}>
              <GridActionsCellItem
                icon={<ArchiveIcon />}
                label="Archive"
                className="textPrimary"
                onClick={() => {
                  putAlarms({ id, alerts, setAlerts, displayAlerts, setDisplayAlerts, intl });
                }}
                color="inherit"
              />
            </Tooltip>,
            userData?.role !== "viewer" ? (
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                className="textPrimary"
                onClick={() => {
                  setCurrentAlert(displayAlerts.find((item) => item.id === id));
                  setOpenTextModal(true);
                }}
                color="inherit"
              />
            ) : (
              <></>
            ),
          ];
        };
      } else {
        result = getColumnsMeasurement(intl);
        result[3] = {
          ...result[3],
          renderCell: (params) => {
            const asset = assetsData?.data?.find((a) => a.name === params.row.asset);
            const equipment = asset?.equipments.find((e) => e.equipmentID === params.row.equipmentID);

            return <span>{equipment.channelsNames[params.row.channel - 1]}</span>;
          },
        };
        result[5] = {
          ...result[5],
          renderCell: (params) => {
            const alertData = getAlertThemeData(params.row.type, "medium", intl);
            if (params.row.measure === "leak" || params.row.measure === "landslide") return null;
            return (
              <Tooltip title={alertData.title} arrow>
                <Chip
                  icon={alertData.icon}
                  label={Math.round(params.value * 100) / 100}
                  sx={{ backgroundColor: alertData.backgroundColor }}
                />
              </Tooltip>
            );
          },
        };
        result[6] = {
          ...result[6],
          renderCell: (params) => {
            if (params.row.measure === "leak" || params.row.measure === "landslide") return null;
            return <span>{params.row.refValue}</span>;
          },
        };
        result[7] = {
          ...result[7],
          renderCell: (params) => {
            const alertData = params.row;
            const fechaOriginal = alertData.dateStart;
            const fechaFormateada = fechaOriginal.replace("T", " ").replace(/(\+\d{2}:\d{2})/, "");
            return <span>{fechaFormateada}</span>;
          },
        };

        result[8] = {
          ...result[8],
          renderCell: (params) => {
            const alertData = params.row;
            const fechaOriginal = alertData.dateEnd;
            const fechaFormateada = fechaOriginal.replace("T", " ").replace(/(\+\d{2}:\d{2})/, "");
            return <span>{fechaFormateada}</span>;
          },
        };
        result[9] = {
          ...result[9],
          renderCell: (params) => {
            const asset = assetsData?.data?.find((a) => a.name === params.row.asset);

            let point = { x: 0, y: 0 };
            if (!params.row.isEquipment && !isNil(assetsPoints[`${asset.name}${params.row.channel}`])) {
              point = assetsPoints[`${asset.name}${params.row.channel}`].points.find(
                (p) => p.pk === params.row.startPoint,
              );
            } else {
              point = asset.equipments[params.row.startPoint];
            }
            return <span>{!isNil(point) ? decimalToDMS(point.latitude) : "None"}</span>;
          },
        };

        result[10] = {
          ...result[10],
          renderCell: (params) => {
            const asset = assetsData?.data?.find((a) => a.name === params.row.asset);

            let point = { x: 0, y: 0 };
            if (!params.row.isEquipment && !isNil(assetsPoints[`${asset.name}${params.row.channel}`])) {
              point = assetsPoints[`${asset.name}${params.row.channel}`].points.find(
                (p) => p.pk === params.row.startPoint,
              );
            } else {
              point = asset.equipments[params.row.startPoint];
            }
            return <span>{!isNil(point) ? decimalToDMS(point.longitude) : "None"}</span>;
          },
        };
        result[11] = {
          ...result[11],
          renderCell: (params) => {
            const asset = assetsData?.data?.find((a) => a.name === params.row.asset);

            let point = { x: 0, y: 0 };
            if (!params.row.isEquipment && !isNil(assetsPoints[`${asset.name}${params.row.channel}`])) {
              point = assetsPoints[`${asset.name}${params.row.channel}`].points.find(
                (p) => p.pk === params.row.startPoint,
              );
            } else {
              point = asset.equipments[params.row.startPoint];
            }
            return (
              <span onClick={() => handlePointClick(point.latitude, point.longitude)} style={{ cursor: "pointer" }}>
                {params.row.startPoint.toFixed(2)}
              </span>
            );
          },
        };
        result[12] = {
          ...result[12],
          renderCell: (params) => {
            if (params.row.isEquipment) {
              return "-";
            }
            const asset = assetsData?.data?.find((a) => a.name === params.row.asset);

            let point = { x: 0, y: 0 };
            if (!params.row.isEquipment && !isNil(assetsPoints[`${asset.name}${params.row.channel}`])) {
              point = assetsPoints[`${asset.name}${params.row.channel}`].points.find(
                (p) => p.pk === params.row.startPoint,
              );
            } else {
              point = asset.equipments[params.row.endPoint];
            }
            return (
              <span onClick={() => handlePointClick(point.latitude, point.longitude)} style={{ cursor: "pointer" }}>
                {params.row.endPoint.toFixed(2)}
              </span>
            );
          },
        };

        result[result.length - 1].getActions = ({ id }) => {
          const auxAlert = displayAlerts.find((item) => item.id === id);
          return [
            <GridActionsCellItem
              icon={<QueryStatsIcon />}
              label="Analysis"
              className="textPrimary"
              onClick={() => {
                const alert = alerts.find((item) => item.id === id);
                const p1 = assetsPoints[`${alert.asset}${alert.channel}`].points.findIndex(
                  (p) => p.pk === alert.startPoint,
                );
                const p2 =
                  alert.startPoint === alert.endPoint
                    ? p1
                    : assetsPoints[`${alert.asset}${alert.channel}`].points.findIndex((p) => p.pk === alert.endPoint);
                const date = alert.dateEnd.replace(" ", "T");
                if (alert.measure === "leak") {
                  const startDate = alert.dateStart.replace(" ", "T");
                  navigate(
                    `/analysis/${alert.asset}/${encodeURIComponent(date)}/${p1 - 12}/${p2 + 12}/${encodeURIComponent(
                      startDate,
                    )}/${alert.measure}`,
                  );
                } else {
                  navigate(`/analysis/${alert.asset}/${encodeURIComponent(date)}/${p1}/${p2}`);
                }
              }}
              color="inherit"
            />,
            userData?.role !== "viewer" ? (
              <Tooltip
                key={`${auxAlert.id}_aux_${auxAlert.isArchive}`}
                title={intl.formatMessage({ id: auxAlert.isArchived ? "unarchive" : "archive" })}
              >
                <GridActionsCellItem
                  icon={<ArchiveIcon />}
                  label="Archive"
                  className="textPrimary"
                  onClick={() => {
                    putAlarms({ id, alerts, setAlerts, displayAlerts, setDisplayAlerts, intl });
                  }}
                  color="inherit"
                />
              </Tooltip>
            ) : (
              <></>
            ),

            userData?.role !== "viewer" ? (
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                className="textPrimary"
                onClick={() => {
                  setCurrentAlert(alerts.find((item) => item.id === id));
                  setOpenTextModal(true);
                }}
                color="inherit"
              />
            ) : (
              <></>
            ),
          ];
        };
      }
    }
    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl, alerts, displayAlerts, userData, assetsData, assetsPoints]);

  function decimalToDMS(decimal) {
    if (!decimal) return "None";
    const degrees = Math.floor(decimal);
    const minutes = Math.floor((decimal - degrees) * 60);
    let seconds = ((decimal - degrees - minutes / 60) * 3600).toFixed(2);
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    return `${degrees}° ${minutes}' ${seconds}"`;
  }
  if (isEmpty(alerts) && !loading) {
    return (
      <div
        style={{ height: "92vh", display: "flex", justifyContent: "center", alignItems: "center" }}
      >{`${intl.formatMessage({ id: "no.data" })}`}</div>
    );
  }
  if (
    (isNil(alerts) ||
      isNil(displayAlerts) ||
      isNil(assetsData?.data) ||
      isNil(assetsPoints) ||
      isEmpty(assetsPoints)) &&
    loading &&
    !isEquipment
  ) {
    return (
      <div
        style={{ height: "92vh", display: "flex", justifyContent: "center", alignItems: "center" }}
      >{`${intl.formatMessage({ id: "loading" })}...`}</div>
    );
  }

  return (
    <>
      <Box
        sx={{
          minHeight: 200,
          height: isNil(alerts) || isEmpty(alerts) ? 400 : "100%",
          width: "99%",
          padding: "1rem",
          "& .actions": {
            color: palette.secondary,
          },
          "& .textPrimary": {
            color: palette.primary,
          },
        }}
      >
        <DataGrid
          rows={displayAlerts?.map((item) => ({ ...item, customValue: item.value }))}
          columns={columns}
          columnVisibilityModel={{ id: false, type: false, value: false }}
          loading={loading}
          localeText={gridLocalTextTheme.components.MuiDataGrid.defaultProps.localeText}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10, 20, 30]}
          pagination
          disableRowSelectionOnClick
          slots={{
            toolbar: EditToolbar,
          }}
          slotProps={{
            toolbar:
              userData?.role !== "viewer"
                ? {
                    btnText: intl.formatMessage({ id: "delete all" }),
                    btnIcon: <ToolbarDeleteIcon />,
                    handleClick: () => setOpenDeleteAllModal(true),
                    btnEnabled: !isNil(displayAlerts) && !isEmpty(displayAlerts),
                    secondText: intl.formatMessage({ id: "see.archived" }),
                    secondIcon: <ArchiveIcon />,
                    secondClick: () => setArchived(!archived),
                    archived: archived,
                    secondEnabled: !isNil(alerts) && !isEmpty(alerts),
                    excelExportOptions: {
                      data: { rows: displayAlerts, columns: columns, points: assetsPoints },
                      filename: `${format(new Date(), "yyyyMMdd")}_${intl.formatMessage({
                        id: "alarms",
                      })}_${intl.formatMessage({ id: "project.name" })}`,
                    },
                  }
                : {},
          }}
          sx={gridTheme}
        />
      </Box>
      <TextModal
        open={openTextModal}
        setOpen={setOpenTextModal}
        title={intl.formatMessage({ id: "delete alert" })}
        text={intl.formatMessage({ id: "delete.alert.text" })}
        acceptFunction={() =>
          handleDeleteAlert({
            id: currentAlert?.id,
            alerts: displayAlerts,
            setAlerts,
            setDisplayAlerts,
            setLoading,
            setOpenTextModal,
            dispatch,
            navigate,
            intl,
          })
        }
      />
      <TextModal
        open={openDeleteAllModal}
        setOpen={setOpenDeleteAllModal}
        title={intl.formatMessage({ id: "delete all alerts" })}
        text={intl.formatMessage({ id: "delete.all.alerts.text" })}
        acceptFunction={() =>
          handleDeleteAlert({
            id: null,
            alerts: displayAlerts,
            setAlerts,
            setDisplayAlerts,
            setLoading,
            setOpenTextModal: setOpenDeleteAllModal,
            dispatch,
            navigate,
            intl,
          })
        }
      />
    </>
  );
};

export default AlarmTable;
