import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import { isEmpty, isNil } from "ramda";

import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";

import { getGridLocalTextTheme, getGridTheme, menuAccessVerification } from "../../utils/utils";
import { getColumns, handleDeleteRole, loadRoles } from "./utils/utils";
import palette from "../../../config/colorPalette";
import EditToolbar from "../../components/EditToolbar";
import RoleModal from "./components/RoleModal";
import TextModal from "../../components/TextModal";

const Roles = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl();

  const userData = useSelector((state) => state.userData.data);
  const menuData = useSelector((state) => state.menuData.data);
  const localeData = useSelector((state) => state.configData.locale);

  const gridLocalTextTheme = useMemo(() => getGridLocalTextTheme(localeData), [localeData]);
  const gridTheme = useMemo(() => getGridTheme(), []);

  const [roles, setRoles] = useState();
  const [loading, setLoading] = useState(true);
  const [openRoleModal, setOpenRoleModal] = useState(false);
  const [openTextModal, setOpenTextModal] = useState(false);
  const [currentRole, setCurrentRole] = useState(null);
  const [modalType, setModalType] = useState("create");

  const columns = useMemo(() => {
    let result = [];
    if (!isNil(roles) && !isNil(userData)) {
      result = getColumns(intl);
      result[result.length - 1].getActions = ({ id }) => {
        const actions = [
          <GridActionsCellItem
            icon={<VisibilityIcon />}
            label="View"
            className="textPrimary"
            onClick={() => {
              setCurrentRole(roles.find((item) => item.id === id));
              setModalType("view");
              setOpenRoleModal(true);
            }}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={() => {
              setCurrentRole(roles.find((item) => item.id === id));
              setModalType("edit");
              setOpenRoleModal(true);
            }}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            className="textPrimary"
            disabled={roles.find((item) => item.id === id).type === userData.role}
            onClick={() => {
              setCurrentRole(roles.find((item) => item.id === id));
              setOpenTextModal(true);
            }}
            color="inherit"
          />,
        ];
        return actions;
      };
    }
    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl, roles, userData]);

  useEffect(() => {
    if (menuAccessVerification("/roles", menuData, navigate)) {
      loadRoles(setRoles, setLoading, dispatch, navigate, intl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuData]);

  const handleNewRoleBtnClick = async () => {
    setModalType("create");
    setCurrentRole(null);
    setOpenRoleModal(true);
  };

  if (isNil(roles) || loading) {
    return (
      <div
        style={{ height: "92vh", display: "flex", justifyContent: "center", alignItems: "center" }}
      >{`${intl.formatMessage({ id: "loading" })}...`}</div>
    );
  }

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div style={{ fontSize: "x-large", padding: "1rem", width: "100%" }}>{intl.formatMessage({ id: "roles" })}</div>
      <Box
        sx={{
          minHeight: 200,
          height: isNil(roles) || isEmpty(roles) ? 400 : "100%",
          width: "99%",
          padding: "1rem",
          "& .actions": {
            color: palette.secondary,
          },
          "& .textPrimary": {
            color: palette.primary,
          },
        }}
      >
        <DataGrid
          rows={roles}
          columns={columns}
          columnVisibilityModel={{ id: false }}
          loading={loading}
          localeText={gridLocalTextTheme.components.MuiDataGrid.defaultProps.localeText}
          pagination
          disableRowSelectionOnClick
          slots={{
            toolbar: EditToolbar,
          }}
          slotProps={{
            toolbar: {
              btnText: intl.formatMessage({ id: "add role" }),
              btnIcon: <AddIcon />,
              handleClick: handleNewRoleBtnClick,
            },
          }}
          sx={gridTheme}
        />
      </Box>
      {openRoleModal && (
        <RoleModal
          open={openRoleModal}
          role={currentRole}
          setOpen={setOpenRoleModal}
          type={modalType}
          acceptFunction={() => loadRoles(setRoles, setLoading)}
        />
      )}
      <TextModal
        open={openTextModal}
        setOpen={setOpenTextModal}
        title={`${intl.formatMessage({ id: "delete role" })}: "${currentRole?.type}"`}
        text={intl.formatMessage({ id: "delete.role.text" })}
        acceptFunction={() =>
          handleDeleteRole({ id: currentRole?.id, setRoles, setLoading, setOpenTextModal, dispatch, navigate, intl })
        }
      />
    </div>
  );
};

export default Roles;
