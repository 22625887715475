import React, { useEffect } from "react";
import * as echarts from "echarts";
import { isEmpty, isNil } from "ramda";

const LineChart = ({
  prefix,
  data,
  environmentalData,
  measureName,
  withToolbox = false,
  resetZoomHandler = () => {},
  pointsSelectHandler = () => {},
  startZoom = 0,
  endZoom = 100,
  startIndex,
  endIndex,
  isAlert,
  alertMeasure,
  afterAlert,
  setAfterAlert,
  alertLines,
  setAlertLines,
  intl,
}) => {
  const dateOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };

  useEffect(() => {
    const chartElement = document.getElementById(`${prefix}-stacked-line-chart`);
    const myChart = echarts.init(chartElement);
    let minData = Number.POSITIVE_INFINITY;
    let maxData = Number.NEGATIVE_INFINITY;
    let minEnvironmentalData = Number.POSITIVE_INFINITY;
    let maxEnvironmentalData = Number.NEGATIVE_INFINITY;
    data?.series?.forEach((series) => {
      series?.data?.forEach((value) => {
        if (!isNil(data) && !contieneTemporaEnParentesis(data.title)) {
          if (value < minData) minData = value;
          if (value > maxData) maxData = value;
        } else {
          if (value[1] < minData) minData = value[1];
          if (value[1] > maxData) maxData = value[1];
        }
      });
    });
    environmentalData?.series?.forEach((series) => {
      series?.data?.forEach((value) => {
        if (!isNil(data) && !contieneTemporaEnParentesis(data.title)) {
          if (value < minEnvironmentalData) minEnvironmentalData = value;
          if (value > maxEnvironmentalData) maxEnvironmentalData = value;
        } else {
          if (value[1] < minEnvironmentalData) minEnvironmentalData = value[1];
          if (value[1] > maxEnvironmentalData) maxEnvironmentalData = value[1];
        }
      });
    });
    const xAxisData =
      (data?.xAxis?.data?.length || 0) > (environmentalData?.xAxis?.data?.length || 0) ? data : environmentalData || {};

    let combinado = null;
    if (!isNil(data) && !isNil(environmentalData) && contieneTemporaEnParentesis(data.title)) {
      combinado = data?.series[0].data.concat(environmentalData?.series[0].data);
      combinado.sort((a, b) => new Date(a[0]) - new Date(b[0]));
      combinado = combinado.map((subArray) => subArray[0]);
    }
    const dataMinRounded = Math.floor(minData * 100) / 100;
    const dataMaxRounded = Math.ceil(maxData * 100) / 100;

    const dataEnvMinRounded = Math.floor(minEnvironmentalData * 100) / 100;
    const dataEnvMaxRounded = Math.ceil(maxEnvironmentalData * 100) / 100;
    const colors = ["#5470C6", "#91CC75", "#EE6666", "#9A60B4", "#FAC858", "#EA7CCC", "#FC8452", "#73C0DE", "#3BA272"];
    const legend = [];
    if (contieneTemporaEnParentesis(data.title)) {
      for (let index = 0; index < data?.series.length; index++) {
        legend.push(intl.formatMessage({ id: data.series[index].title }));
      }
      for (let index = 0; index < environmentalData?.series.length; index++) {
        legend.push(
          environmentalData.series[index].title
            .split("_") // Divide la cadena en partes donde hay '_'
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitaliza la primera letra de cada palabra
            .join(" "),
        );
      }
    }
    console.log(legend);
    const options = {
      color: colors,
      tooltip: !contieneTemporaEnParentesis(data.title)
        ? {
            trigger: "axis",
            position: (pt) => [pt?.[0], "10%"],
          }
        : null,
      legend: !contieneTemporaEnParentesis(data.title) ? { data: data.legend } : { data: legend },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "7%",
        containLabel: true,
      },
      toolbox: withToolbox
        ? {
            feature: {
              dataZoom: {
                yAxisIndex: "all",
                xAxisIndex: "all",
                brushStyle: {
                  color: "#5470C655",
                  borderType: "solid",
                  borderWidth: 1,
                  borderColor: "#5470C6",
                  shadowColor: "#5470C6",
                },
                title: {
                  zoom: intl.formatMessage({ id: "Zoom in" }),
                  back: intl.formatMessage({ id: "Zoom out" }),
                },
              },
              restore: {
                title: intl.formatMessage({ id: "Restore zoom" }),
              },
              saveAsImage: {
                title: intl.formatMessage({ id: "Save as image" }),
              },
              myCustomCSVDownload: {
                show: true,
                title: intl.formatMessage({ id: "Download CSV" }),
                icon: "image://csv-icon80.png",
                onclick: function () {
                  downloadCSV();
                },
              },
            },
          }
        : {},
      xAxis: {
        type: !contieneTemporaEnParentesis(data.title) ? "category" : "time",
        data: !contieneTemporaEnParentesis(data.title)
          ? xAxisData?.xAxis?.data.map((num) => {
              return typeof num === "number" ? parseFloat(num.toFixed(1)) : num;
            })
          : !isNil(combinado)
          ? combinado.map((date) => {
              return new Date(date).toLocaleString("es-ES", dateOptions);
            })
          : null,
        boundaryGap: false,
        splitLine: {
          show: false,
        },
        name: !contieneTemporaEnParentesis(data.title) ? "PK (m)" : `${intl.formatMessage({ id: "time" })}`,
        nameLocation: "middle", // Esto centrará el nombre en el eje
        nameGap: 25,
      },
      yAxis:
        !contieneTemporaEnParentesis(data.title) || isNil(environmentalData)
          ? {
              type: "value",
              min: dataMinRounded === Infinity ? null : dataMinRounded,
              max: dataMaxRounded === -Infinity ? null : dataMaxRounded,
              name:
                measureName === "temperature" || prefix.includes("temperature")
                  ? `${intl.formatMessage({ id: "temperature" })} (Cº)`
                  : measureName === "strain" || prefix.includes("strain")
                  ? `${intl.formatMessage({ id: "strain" })} (μ)`
                  : null,
              nameRotate: 90,
              nameLocation: "middle",
              nameGap: 35,
            }
          : [
              {
                type: "value",
                min: dataMinRounded === Infinity ? null : dataMinRounded,
                max: dataMaxRounded === -Infinity ? null : dataMaxRounded,
                name:
                  measureName === "temperature" || prefix.includes("temperature")
                    ? `${intl.formatMessage({ id: "temperature" })} (Cº)`
                    : measureName === "strain" || prefix.includes("strain")
                    ? `${intl.formatMessage({ id: "strain" })} (μ)`
                    : null,
                nameRotate: 90,
                nameLocation: "middle",
                nameGap: 35,
                axisLine: {
                  show: true,
                  lineStyle: {
                    color: colors[0],
                  },
                },
              },
              {
                type: "value",
                min: dataEnvMinRounded === Infinity ? null : dataEnvMinRounded,
                max: dataEnvMaxRounded === -Infinity ? null : dataEnvMaxRounded,
                name: `${intl.formatMessage({ id: "intensity" })} [A]`,
                nameRotate: 270,
                nameLocation: "middle",
                nameGap: 35,
                axisLine: {
                  show: true,
                  lineStyle: {
                    color: colors[2],
                  },
                },
              },
            ],
      //dataZoom: [
      //  {
      //    type: "inside",
      //    start: 0,
      //    end: 100,
      //    yAxisIndex: [0],
      //    filterMode: "none",
      //  },
      //],
      series: !contieneTemporaEnParentesis(data.title)
        ? data?.series?.map((s) => ({
            ...s,
            type: "line",
            symbolSize: 1,
          }))
        : [
            ...(data?.series?.map((s) => ({
              ...s,
              name: intl.formatMessage({ id: s.title }),
              type: "line",
              symbolSize: 1,
              yAxisIndex: 0,
            })) || []),
            ...(environmentalData?.series?.map((s, index) => ({
              ...s,
              name: s.title
                .split("_") // Divide la cadena en partes donde hay '_'
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitaliza la primera letra de cada palabra
                .join(" "),
              type: "line",
              symbolSize: 1,
              yAxisIndex: 1,
              color: colors[index + 1],
            })) || []),
          ],
    };
    myChart.setOption(options);
    if (!isNil(alertLines) && alertLines.start !== null && alertLines.end !== null) {
      const zoomLines = [
        {
          name: "Zoom Start",
          xAxis: alertLines.start,
          lineStyle: {
            color: "red",
            width: 2,
            type: "solid",
          },
          label: { show: false },
        },
        {
          name: "Zoom End",
          xAxis: alertLines.end,
          lineStyle: {
            color: "red",
            width: 2,
            type: "solid",
          },
          label: { show: false },
        },
      ];

      myChart.setOption({
        series:
          data?.series?.map((s) => ({
            ...s,
            type: "line",
            markLine: {
              symbol: "none",
              data: zoomLines,
            },
          })) || [], // Asegúrate de que sea un array vacío si no hay series
      });
    }
    const addZoomLines = (startValue, endValue) => {
      const zoomLines = [
        {
          name: "Zoom Start",
          xAxis: startValue,
          lineStyle: {
            color: "red",
            width: 2,
            type: "solid",
          },
          label: { show: false },
        },
        {
          name: "Zoom End",
          xAxis: endValue,
          lineStyle: {
            color: "red",
            width: 2,
            type: "solid",
          },
          label: { show: false },
        },
      ];

      myChart.setOption({
        series: data?.series?.map((s) => ({
          ...s,
          type: "line",
          markLine: {
            symbol: "none",
            data: zoomLines,
          },
        })),
      });
    };

    myChart.on("click", (params) => {
      onChartClick(params);
      let i = -Infinity;
      let j = Infinity;
      addZoomLines(i, j);
    });
    myChart.on("dataZoom", (params) => {
      if (params && params.batch) {
        let i = params.batch[0].startValue;
        let j = params.batch[0].endValue;
        if (j - i >= 64) {
          let centerPoint = parseInt((j - i) / 2) + i;
          i = centerPoint >= 32 ? centerPoint - 32 : 0;
          centerPoint += 32;
          const count = data?.series?.[0]?.data?.length ?? 0;
          j = centerPoint < count ? centerPoint : count - 1;
        }
        if (!contieneTemporaEnParentesis(data.title)) {
          addZoomLines(i, j);
        }
        onDataZoom(params, data.series[0].data.length);
      }
    });
    myChart.on("restore", () => {
      resetZoomHandler();
    });
    myChart.on("mousewheel", function (params) {
      params.event.preventDefault(); // Evita el zoom con la rueda del ratón
    });

    return () => {
      myChart.dispose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, environmentalData, prefix, startZoom, endZoom]);

  useEffect(() => {
    if (!isNil(startIndex) && !isNil(endIndex) && isAlert && !(measureName === "strain" && alertMeasure === "leak")) {
      const chartElement = document.getElementById(`${prefix}-stacked-line-chart`);
      const myChart = echarts.init(chartElement);
      let i = startIndex;
      let j = endIndex;
      if (j - i >= 64) {
        let centerPoint = parseInt((j - i) / 2) + i;
        i = centerPoint >= 32 ? centerPoint - 32 : 0;
        centerPoint += 32;
        const count = data?.series?.[0]?.data?.length ?? 0;
        j = centerPoint < count ? centerPoint : count - 1;
      }
      if (!contieneTemporaEnParentesis(data.title)) {
        const zoomLines = [
          {
            name: "Zoom Start",
            xAxis: i,
            lineStyle: {
              color: "red",
              width: 2,
              type: "solid",
            },
            label: { show: false },
          },
          {
            name: "Zoom End",
            xAxis: j,
            lineStyle: {
              color: "red",
              width: 2,
              type: "solid",
            },
            label: { show: false },
          },
        ];
        setAlertLines({ start: i, end: j });

        const iniZoom = Math.floor((startIndex * 100) / data.xAxis.data.length) - 1;
        const finZoom = Math.floor((endIndex * 100) / data.xAxis.data.length) + 2;

        myChart.setOption({
          series: data?.series?.map((s) => ({
            ...s,
            type: "line",
            markLine: {
              symbol: "none",
              data: zoomLines,
            },
          })),
          dataZoom: [
            {
              type: "inside",
              start: iniZoom,
              end: finZoom,
            },
          ],
        });
        if (!afterAlert && !isNil(alertMeasure)) pointsSelectHandler(startIndex, endIndex, false);
        if (isNil(alertMeasure) && !afterAlert) pointsSelectHandler(startIndex, endIndex, afterAlert);
      }
    } else if (!isNil(startIndex) && !isNil(endIndex) && isAlert) {
      if (!afterAlert) pointsSelectHandler(startIndex, endIndex, false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startIndex]);

  function downloadCSV() {
    const header = ["PK", intl.formatMessage({ id: measureName })].join(",");
    const dateTime = data?.series?.[0]?.name?.replace(" ", "T");
    const pkData = data?.xAxis?.data;
    const serialData = data?.series?.[0]?.data;
    const combinedData = pkData?.map((pk, index) => [pk, serialData[index]]);

    const csvData = [header].concat(combinedData.map((row) => row.join(","))).join("\n");

    const blob = new Blob([csvData], { type: "text/csv" });

    const a = document.createElement("a");
    a.href = URL.createObjectURL(blob);
    a.download = `${dateTime}_${measureName}.csv`;

    a.click();

    URL.revokeObjectURL(a.href);
  }
  const onChartClick = (params) => {
    if (params.componentType === "series") {
      const dataIndex = params.dataIndex;
      pointsSelectHandler(dataIndex, dataIndex, afterAlert);
    }
  };
  function contieneTemporaEnParentesis(cadena) {
    const regexParentesis = /\(.*temporal.*\)/i;

    return regexParentesis.test(cadena);
  }

  const onDataZoom = (params, length) => {
    if (
      !isNil(params?.batch) &&
      !isEmpty(params.batch.length) &&
      !isNil(params.batch?.[0]?.startValue) &&
      !isNil(params.batch?.[0]?.endValue)
    ) {
      const startIndex2 = params.batch[0].startValue;
      const endIndex2 = params.batch[0].endValue;
      pointsSelectHandler(startIndex2, endIndex2, afterAlert);
    }
  };

  return <div id={`${prefix}-stacked-line-chart`} style={{ width: "90%", height: "300px" }} />;
};

export default LineChart;
