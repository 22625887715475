import { getAssetsDates, getAssetsDatetimes } from "../../api";
import { isEmpty, isNil } from "ramda";

export const loadDateOptions = async ({
  date,
  date2,
  times,
  asset,
  equipment,
  channel,
  assetEquipments,
  selectedChannels,
  dateOptions,
  setDateOptions = () => {},
  setTimeOptions = () => {},
  setDate = () => {},
  setTime = () => {},
  setDateTimes = () => {},
  setDate2 = () => {},
  setLoading = () => {},
  setChannel,
  firstTime,
  setFirstTime,
  selectedEquipment,
  setSelectedEquipment,
  setLoadingDates,
  setNoData,
}) => {
  setLoadingDates(true);
  if (!isNil(asset) && !isNil(equipment)) {
    let dates = [];
    let datesDividedByEquipmentsAndChannels = [];

    for (let indexEquipment = 0; indexEquipment < assetEquipments.length; indexEquipment++) {
      datesDividedByEquipmentsAndChannels.push([]);
      if (selectedEquipment === assetEquipments[indexEquipment] || firstTime) {
        for (let indexChannel = 0; indexChannel < assetEquipments[indexEquipment].channels.length; indexChannel++) {
          datesDividedByEquipmentsAndChannels[indexEquipment].push([]);

          if (selectedChannels.includes(indexChannel) || firstTime) {
            const response = await getAssetsDates([asset], equipment, indexChannel + 1);
            dates.push(...response.data.data);
            dates.sort((a, b) => new Date(a) - new Date(b));
            const toDivide = response.data.data;
            toDivide.sort((a, b) => new Date(a) - new Date(b));
            datesDividedByEquipmentsAndChannels[indexEquipment][indexChannel] = [...new Set(toDivide)];
            if (!isEmpty(dates)) {
              setFirstTime(false);
              setSelectedEquipment(assetEquipments[indexEquipment]);
            }
          }
        }
      }
    }

    const newDates = [...new Set(dates)];

    if (!isEmpty(newDates)) {
      const dateTimeDef = [];
      const abortController = new AbortController();

      newDates.sort((a, b) => new Date(a) - new Date(b));
      const promises = [];

      for (let indexEquipment = 0; indexEquipment < datesDividedByEquipmentsAndChannels.length; indexEquipment++) {
        if (!isEmpty(datesDividedByEquipmentsAndChannels[indexEquipment])) {
          for (
            let indexChannel = 0;
            indexChannel < datesDividedByEquipmentsAndChannels[indexEquipment].length;
            indexChannel++
          ) {
            if (!isEmpty(datesDividedByEquipmentsAndChannels[indexEquipment][indexChannel])) {
              for (
                let indexDate = 0;
                indexDate < datesDividedByEquipmentsAndChannels[indexEquipment][indexChannel].length;
                indexDate++
              ) {
                const date = datesDividedByEquipmentsAndChannels[indexEquipment][indexChannel][indexDate];
                const equipmentID = assetEquipments[indexEquipment].equipmentID;
                const channel = indexChannel + 1;

                promises.push(
                  getAssetsDatetimes([asset], equipmentID, channel, date, { signal: abortController.signal })
                    .then((timeResponse) => {
                      const timeList = timeResponse.data.data.reverse();
                      const times = timeList.map((time) => ({
                        dateTime: `${date}T${time}`,
                        channel: channel,
                        equipment_ID: equipmentID,
                        asset: asset,
                      }));
                      dateTimeDef.push(...times);
                    })
                    .catch((error) => {
                      if (error.name !== "AbortError") {
                        console.error("Error en la llamada:", error);
                      }
                    }),
                );
              }
            }
          }
        }
      }

      try {
        await Promise.all(promises);
        dateTimeDef.sort((a, b) => new Date(a.dateTime) - new Date(b.dateTime));
        setDateTimes(dateTimeDef);
        setDate(0);
        setDate2(newDates.length - 1);
        setDateOptions(newDates);
        setNoData(false);
        setLoading(false);
      } catch (error) {
        console.error("Error en el procesamiento de datos:", error);
        setLoading(false);
      }
    } else {
      setDateOptions(null);

      setNoData(true);
    }

    setLoadingDates(false);
  }
};

export const generateCSV = (data, dateTime, measureName, intl) => {
  const header = ["PK", intl.formatMessage({ id: measureName })].join(",");
  const pkData = data?.xAxis?.data;
  const serialData = data?.series;
  const combinedData = pkData?.map((pk, index) => [pk, serialData[index].data]);

  const csvData = [header].concat(combinedData.map((row) => row.join(","))).join("\n");

  return csvData;
};
